<template>
  <v-row class="mt-0">

    <v-chip
      class="font-weight-bold ml-3"
      v-if="detailProgram.title"
      close
      @click:close="clearFilter"
      color="primary">
      {{ detailProgram.title }}
    </v-chip>

    <template v-if="isSubscribe == 1">
      <v-col cols="12">
        <div class="d-flex">
          <div class="text-body-1 cl-slate">
            Kelas - kelas yang tersedia dibawah ini dapat Anda akses hingga
            tanggal
            <span class="font-weight-bold cl-black">{{
              formatDate(currentPackage.expired_at)
            }}</span>
          </div>
          <router-link to="/subscription" class="ml-2 font-weight-bold">
            Lihat paket berlangganan Saya
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" v-if="currentPackage.status !== 'active'">
        <v-card class="rounded-lg card-box-shadow">
          <v-card-text>
            <v-row align="center" justify="space-between">
              <div class="pa-3">
                <div class="text-body-1 cl-black font-weight-bold">
                  Paket berlangganan Anda telah habis
                </div>
                <div class="text-body-1 cl-slate">
                  Akses kelas Anda kembali dengan membeli voucher paket
                  berlangganan
                </div>
              </div>
              <div class="pa-3">
                <v-btn color="primary" elevation="0">Beli Paket Berlangganan</v-btn>
                <!-- <a href="#" target="_blank">
                  <v-btn color="primary" elevation="0">Beli Paket Berlangganan</v-btn>
                </a> -->
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <template v-if="myProgram.list.length > 0">
        <v-col
          md="4"
          lg="3"
          cols="6"
          v-for="(list, index) in myProgram.list"
          :key="index"
          class="mb-3"
        >
          <v-card elevation="0" class="rounded-lg" @click="goDetailClass(list)">
            <v-img
              height="150"
              :src="list.program.program_information.cover.url"
            ></v-img>
            <div class="pa-4">
              <div
                class="font-weight-bold mb-1 text-dots-2"
                style="min-height: 48px"
              >
                {{ list.program.title }}
              </div>
              <h3 class="teal--text font-weight-bold mb-2">
                Rp.
                {{
                  formatPrice(list.program.program_information.selling_price)
                }}
              </h3>
              <div class="d-flex align-center mb-2">
                <v-progress-linear
                  class="mr-2"
                  color="primary"
                  background-color="grey lighten-3"
                  height="6"
                  rounded
                  :value="list.results.progress"
                ></v-progress-linear>
                <div class="navy-soft--text">{{ list.results.progress }}%</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          v-if="myProgram.total >= 12"
          md="12"
          lg="12"
          cols="12"
          class="mx-auto text-center py-3"
        >
          <v-btn
            depressed
            :loading="btnLoading"
            color="primary"
            dark
            class="rounded-xl"
            @click="getLoadClass((myProgram.limit += 12))"
            >Tampilkan Lebih Banyak</v-btn
          >
        </v-col>
      </template>
      <v-col v-else cols="12" class="d-flex justify-center">
        <div class="text-center">
          <div class="text-h6 cl-black">Anda belum berlangganan</div>
          <div class="pt-4 pb-5 text-body-1 cl-black">
            Untuk mendapatkan akses ke semua kelas, <br />silakan beli paket
            berlangganan Anda
          </div>
          <v-btn color="primary" elevation="0">Beli Paket Berlangganan</v-btn>
          <!-- <a href="https://shop.yec.co.id/" target="_blank">
            <v-btn color="primary" elevation="0">Beli Paket Berlangganan</v-btn>
          </a> -->
        </div>
      </v-col>
    </template>
    <template v-else-if="isSubscribe == 0">
      <template v-if="myProgram.list.length > 0">
        <v-col cols="12" v-if="$vuetify.breakpoint.name != 'xs'">
          <v-data-table
            :headers="headers"
            :items="myProgram.list"
            :items-per-page="myProgram.limit"
            hide-default-footer
          >
            <template #item.program="{ item: row }">
              <div
                class="py-2 d-flex align-center"
                @click="goDetailClass(row)"
                style="cursor: pointer"
              >
                <v-card outlined class="pa-1 mr-2">
                  <v-img
                    height="56"
                    width="56"
                    contain
                    :src="row.program.program_information.cover.url"
                  ></v-img>
                </v-card>
                <div class="mr-2">
                  <div class="font-weight-bold">{{ row.program.title }}</div>
                  <div>{{ row.program.learning_targets.studies.title }}</div>
                </div>
              </div>
              <!-- <div>{{row}}</div> -->
            </template>
            <template #item.lisensi="{ item }">
              <div style="cursor: pointer" @click="goDetailClass(item)">
                <v-chip dense color="primary">{{ item.license }}</v-chip>
              </div>
            </template>
            <template #item.topik="{ item }">
              <div style="cursor: pointer" @click="goDetailClass(item)">
                {{
                  `${item.results.total_topic_completed}/${item.results.total_topic}`
                }}
              </div>
            </template>
            <template #item.aktivitas="{ item }">
              <div style="cursor: pointer" @click="goDetailClass(item)">
                {{
                  `${item.results.total_activity_completed}/${item.results.total_activity}`
                }}
              </div>
            </template>
            <template #item.progres="{ item }">
              <div class="d-flex flex-nowrap align-center" style="cursor: pointer" @click="goDetailClass(item)">
                <v-progress-linear
                  class="mr-2 rounded"
                  height="8"
                  color="primary"
                  :value="item.results.progress"
                  style="width: 56px"
                />
                <div class="text-no-wrap">{{ item.results.progress }} %</div>
              </div>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" v-if="$vuetify.breakpoint.name == 'xs'">
          <v-row no-gutters>
            <v-col
              md="12"
              lg="12"
              cols="12"
              class="mb-2"
              v-for="(list, index) in myProgram.list"
              :key="index"
              @click="goDetailClass(list)"
            >
              <v-card elevation="0" class="rounded-lg" @click="goDetailClass(list)">
                <v-card-title>
                  <div class="subtitle-1 font-weight-bold">{{ list.program.title }}</div>
                </v-card-title>
                <v-card-subtitle>
                  <div class="font-weight-medium">{{ list.program.learning_targets.studies.title }}</div>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="4">
                      <v-img
                        class="rounded-lg"
                        contain
                        :src="list.program.program_information.cover.url"
                      ></v-img>
                    </v-col>
                    <v-col cols="8">
                      <div>
                        <v-chip dense color="primary">{{ list.license }}</v-chip>
                      </div>
                      <div class="d-flex justify-space-between my-3 black--text">
                        <div>Topik</div>
                        <div>
                          {{`${list.results.total_topic_completed}/${list.results.total_topic}`}}
                        </div>
                      </div>
                      <div class="d-flex justify-space-between my-3 black--text">
                        <div>Aktivitas</div>
                        {{`${list.results.total_activity_completed}/${list.results.total_activity}`}}
                      </div>
                      <div class="d-flex align-center">
                        <v-progress-linear
                          class="mr-2"
                          color="primary"
                          background-color="grey lighten-3"
                          height="6"
                          rounded
                          :value="list.results.progress"
                        ></v-progress-linear>
                        <div class="navy-soft--text">{{ list.results.progress }}%</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="myProgram.total >= 12 && myProgram.limit <= myProgram.total" md="12" lg="12" cols="12" class="mx-auto text-center py-3">
          <v-btn depressed :loading="btnLoading" color="primary" dark class="rounded-xl" @click="loadMoreData">Tampilkan Lebih Banyak</v-btn> 
        </v-col>
      </template>
      <v-col v-else>
        <v-row justify="center">
          <v-col md="4" sm="6">
            <v-img
              class="col-8 mx-auto mt-5 mb-4"
              src="@/assets/images/data_empty.png"
            ></v-img>
            <h6 class="text-h6 text-center mb-5 cl-black">
              Kelas Tidak Ditemukan
            </h6>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: ["isSubscribe"],
  emits: ["loadContentTrue", "loadContentFalse"],
  data() {
    return {
      btnLoading: false,
      detailProgram: {},
      myProgram: {
        list: [],
        total: 0,
        page: 1,
        limit: 12,
        is_from_subscribe: 0,
        sort: "created_at",
        dir: "desc",
      },
      currentPackage: {},
    };
  },
  computed: {
    headers() {
      return [
        { text: "Program", value: "program", sortable: false },
        { text: "Lisensi", value: "lisensi", sortable: false },
        { text: "Topik", value: "topik", sortable: false },
        { text: "Aktivitas", value: "aktivitas", sortable: false },
        { text: "Progres", value: "progres", sortable: false },
      ];
    },
  },
  methods: {
    formatDate(val) {
      return val ? moment(val).locale("en-gb").format("DD MMMM YYYY") : null;
    },
    async getDetailProgram() {
      await this.axios.get(`/users/v1/public/program/detail//${this.$route.query.program}`)
      .then((response) => {
        if (response.status == 200) {
          this.detailProgram = response.data.data;
        }
      })
    },

    clearFilter() {
      this.myProgram.sort = 'created_at'
      this.myProgram.dir = 'desc'
      this.myProgram.limit = 12
      this.detailProgram = {}
      this.$router.push({ name: 'my-class', query: { } })
      this.getClass(true, this.isSubscribe)
    },

    loadMoreData() {
      this.myProgram.limit +=12
      this.getClass(false, this.isSubscribe)
    },

    async getClass(loadContent = true, subscribe = 0) {
      this.btnLoading = true
      if(loadContent) {
        this.$emit('loadContentTrue')
      }
      if (this.isSubscribe == 1) {
        this.getCurrentPackage()
      }

      if(this.$route.query.program) {
        this.getDetailProgram()
      }

      this.myProgram.is_from_subscribe = subscribe
      await this.axios
      .get(`/users/v1/myprogram/list`, {
        params: {
          limit: this.myProgram.limit,
          is_from_subscribe: this.myProgram.is_from_subscribe,
          sort: this.myProgram.sort,
          dir: this.myProgram.dir,
          program_id: this.$route.query.program
        }
      })
      .then((response) => {
        let res = response.data.data;
        this.$emit('loadContentFalse')
        if (response.status == 200) {
          this.myProgram.list = res.list;
          this.myProgram.total = res.total;
          this.btnLoading = false
        }
      });
    },
    getCurrentPackage() {
      this.axios.get(`/subscribe/v1/current_package`).then((response) => {
        if (response.status == 200) {
          this.currentPackage = response.data.data;
        } else {
          this.currentPackage = {};
        }
      });
    },
    getLoadClass(limit) {
      this.btnLoading = true;
      this.axios
        .get(
          `/users/v1/myprogram/list?is_from_subscribe=${this.myProgram.is_from_subscribe}&sort=${this.myProgram.sort}&dir=${this.myProgram.dir}&limit=${limit}`
        )
        .then((response) => {
          this.btnLoading = false;
          let res = response.data.data;
          if (response.status == 200) {
            this.myProgram.list = res.list;
          }
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    goDetailClass(list) {
      // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.$router.push({ name: "detail-class", params: { id: list.id } });
      // check navigator is from iphone
      // if (
      //   navigator.userAgent.indexOf("Safari") > -1 &&
      //   navigator.userAgent.indexOf("Chrome") > -1
      // ) {
      //   this.$router.push({ name: "detail-class", params: { id: list.id } });
      // } else {
      //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      //     this.$router.push({
      //       name: "detail-class-v2",
      //       params: { id: list.id },
      //     });
      //   } else {
      //     this.$router.push({
      //       name: "detail-class-v2",
      //       params: { id: list.id },
      //     });
      //   }
      // }
    },
  },
};
</script>
<style>
.banner-class {
  background: #e65c00; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f9a023,
    #324577
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f9a023,
    #324577
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
