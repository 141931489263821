<template>
	<v-row :class="$vuetify.breakpoint.name == 'xs' ? 'mx-2' : 'ml-1'">
		<div class="font-weight-bold mb-3">{{ act.title }}</div>
		<v-card elevation="0" style="height: 100vh; width: 100%">
			<vue-pdf-app :pdf="act.theory.file.url +'#toolbar=0'" :config="config" style="z-index: 0 !important;"></vue-pdf-app>
		</v-card>
	</v-row>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
	components: {
		VuePdfApp,
	},
	props: {
		act: Object
	},
	data() {
		return {
			config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      },
		}
	}
}
</script>

<style>
.pdf-app .toolbar {
  z-index: 0 !important;
}
</style>