<template>
  <v-row align-content="center">
    <v-col class="py-0 mx-auto" md="12">
      <v-row>
        <v-col v-show="send" class="white mx-auto" md="6" style="height: 100vh">
          <v-spacer class="py-3"></v-spacer>
          <router-link
            class="font-weight-medium text-decoration-none px-8"
            tabindex="-1"
            color="primary"
            :to="{ name: 'login' }"
          >
            <v-btn outlined elevation="0">
              <span><v-icon color="primary"> mdi-arrow-left</v-icon> Kembali Login</span>
            </v-btn>
          </router-link>
          <v-spacer class="py-4"></v-spacer>
          <v-form class="px-8" @submit.prevent="submit" lazy-validation ref="formku" v-model="valid">
            <div class="mb-4">
              <span class="fs-20 font-weight-medium">Masukkan nomor Whatsapp yang terdaftar, kami akan mengirimkan kode verifikasi untuk memperbarui kata sandi</span>
            </div>
              <v-alert type="error" text dense prominent v-show="response !== null">{{ response }}</v-alert>
              <v-alert type="success" text dense prominent v-show="responsesukses !== null">{{ responsesukses }}</v-alert>
            <v-row>
              <v-col class="pb-0">
                <div class="mb-2 d-md-flex justify-space-between">
                  <div class="font-weight-medium">Nomor Whatsapp</div>
                </div>
                <v-text-field
                  v-model="nomer"
                  placeholder="081234xxx"
                  :rules="nomerRules"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
                <!-- <span class="fs-12 text-danger">*Harap mengganti awalan nomor dengan angka 62. Cth:62897654321</span> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-2">
                <v-btn
                  large
                  elevation="0"
                  color="primary"
                  :loading="submiting"
                  block
                  @click="otp()"
                >
                  Kirim
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col v-show="verify" class="py-0 mx-auto" md="12">
          <v-row>
            <v-col class="white mx-auto" md="6" style="height: 100vh">
              <v-spacer class="py-16"></v-spacer>
              <div class="px-8">
                <v-row class="mb-0" justify="center">
                  <v-col sm="6" lg="12" class="text-center">
                    <v-icon class="text-h2 color-strike">mdi-message-processing-outline</v-icon>
                    <div class="text-h5 color-strike">Masukan Kode Verifikasi</div>
                    <p class="text-subtitle-1 color-disable">Kode verifikasi telah dikirim melalui WA ke {{this.nomer}}</p>
                  </v-col>
                </v-row>
                <v-form lazy-validation ref="form" v-model="valid" class="mb-2">
                  <v-container>
                    <v-alert type="error" text dense prominent v-show="response !== null">{{ response }}</v-alert>
                    <v-row>
                      <v-col
                        id="kode"
                        cols="12"
                        sm="6"
                        class="mx-auto py-0"
                      >
                        <!-- <v-text-field
                          v-model="kodeOtp"
                          required
                          :rules="kodeRules"
                        ></v-text-field> -->
                        <v-otp-input
                          :disabled="loading"
                          @finish="reset()"
                          length="6"
                          v-model="kodeOtp"
                        ></v-otp-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <div class="text-subtitle-2 text-center color-disable mb-3">
                  <span v-show="timerMessage">Mohon tunggu dalam <b>{{ totalSeconds | minutesAndSeconds }} detik </b> untuk kirim ulang</span>
                  <span v-show="resend">
                    Tidak menerima kode ? 
                    <v-btn
                      plain
                      color="primary"
                      @click="sendOtp()"
                      class="pa-0 font-weight-bold"
                    >Kirim ulang
                    </v-btn>                
                  </span>
                </div>
                <!-- <v-btn
                  rounded
                  block                  
                  color="primary"
                  class=""
                  @click="reset()"
                >Verfikasi
                </v-btn> -->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "password-send-email",
  data() {
    return {
      errors: [],
      loading:false,
      valid:true,
      nomer: "",
      nomerRules: [
        v => !!v || 'Nomor Whatsapp tidak boleh kosong',
      ],
      submiting: false,
      response:null,
      responsesukses:null,
      send:true,
      verify:false,
      kodeOtp:"",
      totalSeconds:60,
      timer:{},
      interval:{},
      timerMessage:true,
      resend:false,
      idMember:'',
      kodeRules:[
        (v) => !!v || "Kode OTP tidak boleh kosong",
        (v) => (v && v.length === 6) || "Kode OTP harus 6 karakter",
      ],
    };
  },
  filters: {
    minutesAndSeconds(value) {
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${seconds}`;
    },
  },
  methods: {
    submit() {
      // this.$router.push({ name: 'success-send-email'})
    },
    otp() {
      this.submiting = true;
      if (this.$refs.formku.validate()) {
        let data = { phone:this.nomer,access_key : process.env.VUE_APP_ACCESS_KEY};

        this.axios
          .put(
            `/api/v1/reset/verify/phone`,data)
          .then((res) => {
            this.submiting = false;
            this.idMember=res.data.data.id;
            // console.log(this.idMember)
            this.verify=true;
            this.send=false;
            this.timer_start();
            this.response = null;
          })
          .catch((res) => {
            this.submiting = false;
            this.$refs.formku.reset();
            console.log(res.data)            
            this.response = "Nomor Whatsapp Anda tidak terdaftar";
            // console.error(error);
          });
      } else{
        this.submiting=false;
      }     
    },
    timer_start() {
      this.timer = setInterval(() => {
        if (this.totalSeconds === 0) {
          this.totalSeconds = 60;
          clearInterval(this.timer);
          this.timerMessage=false;
          this.resend=true;
        } 
        this.totalSeconds -= 1;
      }, 1000);
    },
    sendOtp(){
      this.timerMessage=true;
      this.resend=false;
      this.timer_start();
      this.otp();
    },
    reset(){
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let data = { otp:this.kodeOtp};

        this.axios
          .post(
            `/api/v1/reset/verify/otp/${this.idMember}`,data)
          .then((res) => {
            this.submiting = false;
            this.$router.push({ name: 'password-reset' ,params: { id: res.data.data.token }})
            console.log(res.data)                        
          })
          .catch((res) => {
            this.submiting = false;
            this.verify=true;
            this.send=false;
            console.log(res.data)            
            this.response = "Kode OTP tidak valid";
            // console.error(error);
          });
      }else{
        this.submiting=false;
      }

    }
  },
};
</script>

<style>

</style>