<template>
  <div class="px-2 py-2">
    <section id="detail-kelas" class="py-5">
      <div class="mx-auto col-md-8">
        <v-breadcrumbs large :items="items" class="ps-0"></v-breadcrumbs>
        <v-card elevation="0" class="">
          <v-tabs id="" v-model="tab" background-color="transparent">
            <v-tab class="text-capitalize">Data Diri</v-tab>
            <v-tab class="text-capitalize">Ubah Password</v-tab>
          </v-tabs>
          <v-divider class="mb-4"></v-divider>
          <v-tabs-items v-model="tab" class="setting transparent">
            <v-tab-item>
              <v-row>
                <v-col cols="12" md="2" class="">
                  <div v-show="false" class="text-center profile ma-auto">
                    <div class="photo-upload">
                      <div class="mb-3 file-upload-form">
                        <input
                          type="file"
                          @change="previewThumbnail"
                          name="photo-upload-button"
                          id="photo-upload-button"
                          accept="image/*"
                          class="input-file"
                          :disabled="user.is_updated_profile"
                        />
                        <label for="photo-upload-button" color="primary"
                          ><v-icon>ri-image-add-line</v-icon></label
                        >
                      </div>
                      <div
                        class="mb-3 image-preview"
                        v-if="imageData.length > 0"
                      >
                        <img :src="imageData" class="image-preview__img" />
                      </div>
                      <!-- <p>Budi Darma</p> -->
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="7" class="ps-2">
                  <!-- <v-alert class="ms-4 fs-12 accent-blue me-2">
                    <div class="d-flex">
                      <v-icon class="me-2 color-blue align-start"
                        >ri-information-fill</v-icon
                      >
                      <p class="mb-0 color-blue fs-14">
                       Akun ini hanya dapat digunakan untuk 1 peserta pelatihan, pastikan nama Anda sesuai dengan nama yang terdaftar pada akun prakerja.go.id (perhatikan penggunaan huruf dan tanda baca, wajib sama dengan nama akun di prakerja.go.id). Hal ini akan berpengaruh pada sertifikat Kartu Prakerja Anda
                      </p>
                    </div>
                  </v-alert> -->
                  <v-alert
                    class="ms-4"
                    type="error"
                    text
                    v-show="response !== null"
                    >{{ response }}</v-alert
                  >
                  <v-alert
                    class="ms-4"
                    type="success"
                    text
                    v-show="responsesukses !== null"
                    >{{ responsesukses }}</v-alert
                  >
                  <v-form
                    class="pb-5 ps-2"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <!-- <v-row> -->
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Nama Lengkap</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="fullname"
                        placeholder="Masukkan nama lengkap"
                        required
                        outlined
                        hide-details="auto"
                        :rules="nameRules"
                        :readonly="user.is_updated_profile"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Nomor Whatsapp</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="user.phone"
                        placeholder="Masukkan nomor Whatsapp"
                        required
                        outlined
                        readonly
                        hide-details="auto"
                        :rules="phoneRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Email</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="user.email"
                        placeholder="Masukkan email"
                        :readonly="user.is_updated_profile"
                        required
                        outlined
                        hide-details="auto"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Nomor Kartu Prakerja</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="code"
                        placeholder="Masukkan Nomor Kartu Prakerja"
                        :readonly="user.is_updated_profile"
                        required
                        outlined
                        hide-details="auto"
                        :rules="codeRules"
                      ></v-text-field>
                    </v-col> -->
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Tanggal Lahir</span>
                      </div>
                      <v-text-field
                        v-if="this.user.is_updated_profile === true"
                        v-model="user.date_of_birth"
                        :rules="dateRules"
                        :readonly="user.is_updated_profile"
                        outlined
                        class="rounded-xl"
                      ></v-text-field>
                      <v-menu
                        v-else
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        elevation="0"
                        style="min-width:auto!important"
                      >
                        <v-date-picker
                          v-model="user.date_of_birth"
                          ref="picker"
                          min="1950-01-01"
                          :max="(new Date()).toISOString()"
                          @change="save"
                          outlined
                          :rules="dateRules"
                          required
                          :readonly="user.is_updated_profile"
                        ></v-date-picker>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="user.date_of_birth"
                            append-icon="ri-calendar-line"
                            :rules="dateRules"
                        readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="rounded-xl"
                          ></v-text-field>
                        </template>
                      </v-menu>
                    </v-col>                    
                    <!-- </v-row> -->
                    <!-- <v-row> -->
                    <!-- <v-col cols="12" class=""> -->
                    <v-btn
                      v-if="!user.is_updated_profile"
                      large
                      elevation="0"
                      color="primary"
                      class="ms-3 rounded-xl"
                      @click="dialog.confirm = true"
                    >
                      Simpan informasi
                    </v-btn>
                    <!-- </v-col> -->
                    <!-- </v-row> -->
                  </v-form>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="12" md="2" class=""> </v-col>
                <v-col cols="" md="7" class="ps-2">
                  <v-alert
                    class="ms-4"
                    type="success"
                    text
                    v-show="resSuccess !== null"
                    >{{ resSuccess }}</v-alert
                  >
                  <v-alert
                    class="ms-4"
                    type="error"
                    text
                    v-show="resFailed !== null"
                    >{{ resFailed }}</v-alert
                  >
                  <v-form
                    class="pb-5 ps-2"
                    ref="formChange"
                    v-model="validPsw"
                    lazy-validation
                  >
                    <v-col class="mb-3">
                      <div class="mb-2">
                        <span class="font-weight-medium">Password Saat Ini</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="current_psw"
                        placeholder="Tuliskan password saat ini"
                        required
                        :rules="current_rules"
                        ref="input"
                        outlined
                        hide-details="auto"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show = !show"
                      ></v-text-field>
                    </v-col>
                    <v-col class="mb-3">
                      <div class="mb-2">
                        <span class="font-weight-medium">Password Baru</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="new_psw"
                        placeholder="Tuliskan password baru"
                        required
                        :rules="new_rules"
                        ref="input"
                        outlined
                        hide-details="auto"
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                    <v-col class="mb-3">
                      <div class="mb-2">
                        <span class="font-weight-medium">Konfirmasi Password Baru</span>
                      </div>
                      <v-text-field
                        class="rounded-xl"
                        v-model="confirm_psw"
                        placeholder="Tuliskan konfirmasi password baru"                        
                        required
                        :rules="confirm_rules"
                        ref="input"
                        outlined
                        hide-details="auto"
                        :type="show3 ? 'text' : 'password'"
                        :append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show3 = !show3"
                      ></v-text-field>
                    </v-col>
                    <v-col class="mb-3">
                      <v-btn
                        large
                        block
                        elevation="0"
                        color="primary"
                        class="rounded-xl"
                        :loading="submiting"
                        @click="changePassword()"
                        :disabled="!new_psw && !confirm_psw"
                      >
                        Ganti Password
                      </v-btn>
                    </v-col>
                  </v-form>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-dialog v-model="dialog.confirm" width="500">
          <v-card>
            <v-card-title class="font-weight-bold fs-24 text-muted">
              Perhatian
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-1 fs-14">
              <span class="mb-2 color-black"
                >
                Apakah data yang Anda masukkan sudah yakin benar?
              </span>
              <v-alert dense text color="warning" class="px-1 mt-2 fs-12">
                <v-icon color="warning">mdi-information-outline</v-icon>
                <strong> Data yang sudah disimpan tidak dapat diubah. </strong>
              </v-alert>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                outlined
                class="py-6 fs-18 pa-4"
                @click="dialog.confirm = false"
              >
                Batalkan
              </v-btn>
              <v-btn
                color="primary"
                class="py-6 fs-18 pa-4"
                @click="saveSetting()"
              >
                Ya, Benar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "account-setting",
  data() {
    return {
      items: [
        {
          text: "Beranda",
          disabled: false,
          color: "primary",
          href: "/beranda",
        },
        {
          text: "Settings",
          disabled: true,
          href: "#",
        },
      ],
      show: false,
      show2: false,
      show3: false,
      tab: null,
      valid: true,
      menu: false,
      date: null,
      age: "",
      nomor: "",
      imageData: "",
      nameRules: [
        (v) => !!v || "Nama Lengkap Tidak Boleh Kosong",
        // v => /^[A-Za-z ]+$/.test(v) || 'Tidak boleh ada simbol',
      ],      
      codeRules: [(v) => !!v || "Nomor Kartu Prakerja Tidak Boleh Kosong"],
      phoneRules: [
        (v) => !!v || "Nomor Tidak Boleh Kosong",
        // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      emailRules: [
        (v) => !!v || "Email Tidak Boleh Kosong",
        v => /.+@.+\..+/.test(v) || 'E-mail tidak valid',
      ],
      dateRules: [(v) => !!v || "Tanggal Lahir Tidak Boleh Kosong"],

      validPsw: true,
      current_psw: "",
      new_psw: "",
      confirm_psw: "",
      current_rules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      new_rules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(v) || 'Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial (minimal 8 karakter)',
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      confirm_rules: [
        (v) => !!v || "Konfirmasi password tidak boleh kosong",
        (v) => v === this.new_psw || "Konfirmasi password tidak sesuai dengan password baru",
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(v) || 'Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial (minimal 8 karakter)',
      ],

      submiting: false,
      dialog: {
        confirm: false,
      },
      response: null,
      responsesukses: null,
      resSuccess: null,
      resFailed: null,
      changeRead: false,
      changeStatus: false,
      detail: {},
      fullname:'',
      code:'12345'
    };
  },
  components: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    fullname(value) {
      this.fullname = value
        .split(" ")
        .map((val) => {
          return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
        })
        .join(" ");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    
  },
  mounted() {
    // console.log('token',this.user.is_updated_profile);
    this.nomor = this.user.phone;
    this.fullname=this.user.fullname;

    // this.changePsw();
  },
  created(){
    // this.code = this.user.meta?.code || '';
    
  },
  methods: {
    
    previewThumbnail: function getPreview(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      var today = new Date();
      var birthDate = new Date(this.date);
      this.age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        this.age--;
      }
      return this.age;
    },
    changePsw() {
      if (this.user.provider == "email") {
        this.showEmail = true;
      } else {
        this.showEmail = false;
      }
    },
    saveSetting() {
      this.dialog.confirm = false;
      this.submiting = true;
      const sk = this.$refs.form.validate();
      if (sk == true) {
        this.submiting = false;
        this.changeRead = true;

        this.axios
          .put(
            `/users/v1/member/update`,
            {
              fullname: this.fullname,
              date_of_birth: this.user.date_of_birth,
              phone: this.user.phone,
              email:this.user.email,
              meta:{code:'12345'}
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.loading = false;

            this.$store.dispatch("get_user");
            if (response.status == 200) {
              this.loading = false;
              this.responsesukses = "Data Anda berhasil disimpan";
              this.getDetail();
              setTimeout(() => {
                this.responsesukses = null;
                this.$router.push({ name: "my-class" });
              }, 3000);
              // window.location.reload(true);
            }
          });
        // .catch(error => {
        //   // Code here
        // })
      } else {
        this.submiting = false;
        // this.user.email = "";
        // this.user.fullname = "";
        // this.user.date_of_birth = null;
        // this.user. = "";

        this.response = "Silahkan cek kembali data Anda";
        setTimeout(() => {
          this.response = null;
        }, 3000);
      }
    },
    validation() {

      if(this.new_psw == '') {
        this.resFailed = "Kolom password tidak boleh kosong";
        setTimeout(() => {
          this.resFailed=null;
        },3000);
      }else {
        if(this.new_psw.length < 8 || (this.confirm_psw.length < 8) ) {
          this.resFailed = "Password kurang dari 8 character";
          setTimeout(() => {
            this.resFailed=null;
          },3000);
        }

        // check if the new password must contain at least one uppercase letter, one lowercase letter, one number, and one special character

        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]$/.test(this.new_psw)) {
          this.resFailed = "Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial";
          setTimeout(() => {
            this.resFailed=null;
          },3000);
        }
        
      }

      if(this.confirm_psw == '') {
        this.resFailed = "Kolom konfirmasi password tidak boleh kosong";
        setTimeout(() => {
          this.resFailed=null;
        },3000);
      }else {
        if(this.confirm_psw != this.new_psw) {
          this.resFailed = "Konfirmasi password tidak sama dengan password baru";
          setTimeout(() => {
            this.resFailed=null;
          },3000);
        }
      }
      
    },
    changePassword(){
      if (this.$refs.formChange.validate()) {
          this.submiting= true;
          let current_password=this.current_psw;
          let new_password=this.new_psw;
          let conf_new_password=this.confirm_psw;

          this.axios
          .put(`/users/v1/member/change-password/`, {current_password,new_password,conf_new_password},{
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            let res = response.data;
            this.loading = false;
            if (res.status == 200) {
              this.loading = false;
              this.submiting = false;
              this.resSuccess = "Password Anda berhasil diubah ";
              setTimeout(() => {
                this.resSuccess=null;
              },3000);
              let email = this.user.email;
              let password = this.new_psw;
              // console.log('password',password)
              this.$store
                .dispatch("login", { email, password })
                .then(() => {
                  this.submiting = false;
                  if (localStorage.getItem("ytc_lms_redirect") != null) {
                    this.$router.push(localStorage.getItem("ytc_lms_redirect"));
                    localStorage.removeItem("ytc_lms_redirect");
                  }
                })

              this.$refs.formChange.reset();
            }
        })
           .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            let errorData = error.response.data;

            this.resFailed = errorData.message;

            setTimeout(() => {
              this.resFailed = null;
            }, 2000);
          });
      }
      
    }
  },
};
</script>

<style>
.accent-blue {
  background-color: #d4e5fe !important;
  /*opacity: 0.4;*/
  border-radius: 4px;
}
.photo-upload {
  height: auto;
  margin: 10px;
  position: relative;
  text-align: center;
  width: 100%;
}
.input-file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}
.input-file:focus + label {
  border-color: #e5e5e5;
}
.input-file + label {
  background: transparent;
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  color: #5b5b5b;
  display: inline-block;
  font-size: 12pt;
  font-weight: 500;
  height: 100px;
  line-height: 100px;
  text-align: center;
  width: 100px;
}
.input-file + label:hover,
.input-file + label:focus {
  border-color: #e5e5e5;
  cursor: pointer;
}
.image-preview {
  border-radius: 50%;
  height: 100px;
  left: -4px;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}
.image-preview-change {
  border-radius: 50%;
  height: 100px;
  left: -4px;
  margin: 0 auto;
  pointer-events: none;
  position: relative;
  right: 0;
  top: 0;
  width: 100px;
  border: 2px solid #e5e5e5;
}
.image-preview__img {
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100px;
}

</style>