var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:_vm.$vuetify.breakpoint.name == 'xs' ? 'mx-2' : 'ml-1'},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.act.title))]),_c('v-card',{staticClass:"col-md-12",attrs:{"elevation":"0"}},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"pa-4"},[_c('p',{staticClass:"text-body-1 text-justify mb-4",domProps:{"innerHTML":_vm._s(this.act.theory.description)}}),(_vm.showMeetingLink)?[(_vm.meetingSchedule.is_day)?_vm._l((_vm.meetingSchedule.schedules),function(item,index){return _c('div',{key:index},[_c('p',[_vm._v(" Hari "+_vm._s(_vm.day(item.day))+", "),_vm._l((item.times),function(time,index2){return [(
                      _vm.jamSekarang <= time.end_time &&
                      _vm.jamSekarang >= time.start_time
                    )?_c('span',{key:index2},[_vm._v(" pukul "+_vm._s(time.start_time)+" - "+_vm._s(time.end_time)+" WIB"),_c('br')]):_vm._e()]})],2)])}):_vm._l((_vm.meetingSchedule.schedules),function(item,index){return _c('div',{key:index},[_c('p',[_vm._v(" Tanggal "+_vm._s(_vm.date(item.date))+", "),_vm._l((item.times),function(time,index2){return [(
                      _vm.jamSekarang <= time.end_time &&
                      _vm.jamSekarang >= time.start_time
                    )?_c('span',{key:index2},[_vm._v(" pukul "+_vm._s(time.start_time)+" - "+_vm._s(time.end_time)+" WIB"),_c('br')]):_vm._e()]})],2)])}),_c('div',{staticClass:"py-4"}),(_vm.act.type == 'berdiskusi_daring')?_c('p',[_vm._v("Link Meeting :")]):_vm._e(),_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"white px-2",attrs:{"small":"","color":"primary","dark":"","href":this.act.theory.meeting.link,"target":"_blank"},on:{"click":_vm.handleClickMeetingLink}},[_vm._v(" Klik Disini ")])],1),_c('div',{staticClass:"mt-3"},_vm._l((_vm.metaData),function(item,index){return _c('div',{key:'A' + index},[_c('p',[_vm._v(_vm._s(item.title)+" : "+_vm._s(item.content))])])}),0)]:_vm._e(),_c('v-card',{staticClass:"py-3 rounded-lg",attrs:{"elevation":"0"}},[(this.act.theory.link_embed.url != '')?_c('iframe',{staticStyle:{"height":"500px","border-style":"hidden"},attrs:{"src":this.act.theory.link_embed.url}}):_vm._e()]),(_vm.isFinishSchedule)?[(_vm.act.type == 'berdiskusi_daring')?_c('div',{staticClass:"my-4"},[_vm._v(" Segera klik \"Selesaikan Aktivitas\" setelah jadwal live webinarmu berakhir!: ")]):(_vm.act.type == 'berdiskusi_luring')?_c('div',{staticClass:"my-2"},[_vm._v(" Segera klik \"Selesaikan Aktivitas\"!: ")]):_vm._e(),_c('v-btn',{staticClass:"px-2 rounded-lg",attrs:{"depressed":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.handleClickFinish()}}},[_vm._v(" Selesaikan Aktivitas ")])]:_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }