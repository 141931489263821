var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-2 transparent",attrs:{"elevation":"0"}},[_c('vue-html2pdf',{ref:"certifPeserta",attrs:{"enable-download":true,"preview-modal":false,"show-layout":true,"pdf-quality":2,"filename":_vm.certificateName,"pdf-orientation":"landscape","manual-pagination":true,"pdf-content-width":"100%"},on:{"hasDownloaded":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[[_c('section',{staticClass:"pdf-item",style:({
            position: 'relative',
            backgroundImage: _vm.customBg.front,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })},[_c('div',{staticStyle:{"position":"relative","top":"41%","color":"#000","/* font-family":"'Great Vibes', cursive","*/\n              font-style":"normal","font-weight":"900","font-size":"28px","line-height":"auto","left":"18%","padding-bottom":"5px"}},[_vm._v(" "+_vm._s(_vm.data.user_name)+" ")]),_c('div',{staticStyle:{"position":"absolute","width":"100%","top":"58%","color":"#000","font-family":"'PT Sans', sans-serif","font-style":"normal","font-weight":"900","font-size":"22px","line-height":"auto","left":"18%","padding-bottom":"5px"}},[_vm._v(" "+_vm._s(_vm.data.program_name)+" ")]),_c('div',{staticStyle:{"font-family":"'PT Sans', sans-serif","font-style":"normal","font-weight":"bold","font-size":"16px","line-height":"14px","text-align":"center","position":"absolute","bottom":"12%","display":"flex","justify-content":"start","align-items":"center","gap":"15px"},style:(`
              right: ${_vm.data.program_name.toLowerCase() != 'Bahan Pendukung Pertanian untuk Petani Terampil'.toLowerCase() ? '28%' : '26%'};
            `)},[(_vm.data.is_prakerja == 1)?_c('img',{attrs:{"src":require('@/assets/images/prakerja_.png'),"width":"80","alt":"logo prakerja"}}):_vm._e(),(_vm.data.program_name.toLowerCase() == 'Bahan Pendukung Pertanian untuk Petani Terampil'.toLowerCase())?_c('img',{attrs:{"src":require('@/assets/images/logo_AAI.png'),"width":"60","alt":"logo aai"}}):_vm._e()])]),(_vm.data.program_name.toLowerCase() == 'Perencana Kurikulum Kelas Inklusi ( Luring )'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
            backgroundImage: `url('${require('@/assets/images/certificate/Perencana Kurikulum Kelas Inklusi ( Luring ).png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })}):(_vm.data.program_name.toLowerCase() == 'Perencana Kurikulum Kelas Inklusi ( Daring )'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
            backgroundImage: `url('${require('@/assets/images/certificate/Perencana Kurikulum Kelas Inklusi ( Daring ).png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })}):(_vm.data.program_name.toLowerCase() == 'Komunikasi Bahasa Inggris Bagi Programmer'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
            backgroundImage: `url('${require('@/assets/images/certificate/Komunikasi Bahasa Inggris Bagi Programmer.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })}):(_vm.data.program_name.toLowerCase() == 'Optimasi Pembuatan Konten Sosial Media dengan AI'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
            backgroundImage: `url('${require('@/assets/images/certificate/Optimasi Pembuatan Konten Sosial Media dengan AI.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          })}):_vm._e()]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }