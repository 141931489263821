<template>
  <div class="py-3">
    <!-- <section id="section-trans" class="mb-5">
      <div class="col-md-11 mx-auto">
          <div class="row no-gutters align-center">
            <div class="col-md-8 mx-auto text-center white--text">
              <p class=" text-h3"> Pencarian Program</p>
              <v-card class="pa-3">                              
                <div class="row align-center">
                  <div class="col-md-10">
                    <v-spacer class="py-3"></v-spacer>
                    <v-text-field
                      placeholder="Cari program yang anda inginkan"
                      outlined
                      prepend-inner-icon="ri-search-line"
                      class="rounded-lg"
                    ></v-text-field>
                  </div>
                  <div class="col-md-2">
                    <v-btn
                      depressed                      
                      color="primary"
                      class="font-weight-bold"
                      block
                    >
                      Cari
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </div>            
          </div>
      </div>      
    </section> -->
    <section id="kategori" class="py-5">
      <div class="col-md-10 mx-auto">
        <div class="row">
          <div class="col-md-3">
            <h2 class="mb-1">Pencarian Program</h2>
            <p class="color-disable">Menampilkan {{ total_program }} program</p>
            <v-card class="rounded-lg pa-3 d-none d-lg-block" elevation="2"> 
              <div class="box-category mb-2">
                <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
                <h3 class="mb-3">Bidang Studi</h3>
               <!--  <v-checkbox
                  v-model="studi_id"
                  label="Semua Program"
                  @click="getList()"
                  value=""
                  hide-details
                  class="mt-1"                  
                  ></v-checkbox> -->
                <v-checkbox
                  v-for="(item, index) in list_studi" :key="index"
                  v-model="studi_id"
                  :label="item.title"
                  :value="item.id"
                  @click="getList()"
                  hide-details
                  class="mt-1"
                  multiple
                  ></v-checkbox>
                  <v-btn v-if="list_studi.length <= 10" color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='-1')" text block>Tampilkan semua <v-icon>ri-arrow-down-s-line</v-icon></v-btn>
                  <v-btn v-else color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='8')" text block>Sembunyikan <v-icon>ri-arrow-up-s-line</v-icon></v-btn>
              </div>
              <div id="title-prog" class="box-category mb-3">
                <h3 class="">Tipe Program</h3>
                  <!-- <v-select
                  :items="list_progs"
                  :item-text="'name'"
                  :item-value="'id'"
                  placeholder="Pilih label program"
                  v-model="label_program"
                  @change="getListType()"
                  dense
                  outlined
                  class="rounded-pill" 
                  hide-details="auto"                 
                ></v-select> -->
                <v-radio-group v-model="label_program" class="mt-2">
                  <v-radio
                    v-for="(item, index) in list_progs" :key="index"
                    :label="item.name"
                    :value="item.id"
                    @click="getListType()"
                    hide-details
                    ></v-radio>
                </v-radio-group>
              </div>
              <div class="box-category mb-5">
                <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
                <h3 class="mb-3">Harga</h3>
                <v-text-field
                                  
                  v-model="price_start"
                  placeholder="Harga terendah"
                  outlined
                  prefix="Rp"
                  class="rounded-xl mb-3"
                  hide-details="auto"
                ></v-text-field>
                
                <v-text-field
                                  
                  v-model="price_end"
                  placeholder="Harga tertinggi"
                  outlined
                  prefix="Rp"
                  class="rounded-xl"
                  hide-details="auto"
                ></v-text-field>
              </div>
              <div class="box-button mb-2">
                <div class="row">
                  <div class="col-6 px-2">
                    <v-btn
                      outlined                      
                      color="primary"
                      class="rounded-xl font-weight-normal"
                      block
                      small
                      @click="getReset"
                    >
                      Reset Filter
                    </v-btn>
                  </div>
                  <div class="col-6 px-2">
                    <v-btn
                      depressed                      
                      color="primary"
                      class="rounded-xl font-weight-normal"
                      block
                      small
                      @click="getFilter"
                    >
                      Terapkan
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <v-btn class="filter-search d-lg-none d-md-block primary--text bg-white rounded-xl" @click="filter = !filter">
            Filter <v-icon class="ms-3">ri-filter-3-fill</v-icon>
          </v-btn>
          <div class="col-md-9">
            <!-- <h2 class="mb-3">Hasil Pencarian</h2> -->
            <v-text-field
              v-model="search"                           
              placeholder="Cari program yang anda inginkan"
              solo
              append-icon="ri-search-line"
              class="rounded-xl mb-1 box-search"
              @keydown.enter="getList"
            ></v-text-field>            
            <div class="row mb-5">
              <div v-if="total_program === 0" class="col-md-12 pt-12">
                <v-col md="6"  class="text-center mx-auto">
                  <div class="text-center" align-content="center">
                    <!-- <v-col> -->
                      <img src="@/assets/images/data_empty.png" class="mb-3">
                      <div class="title text-center color-disable">Program tidak ditemukan</div>
                    <!-- </v-col> -->
                    <!-- <div class="subtitle body-1 text-center">
                      klik tombol di bawah ini untuk menambahkan program baru
                    </div>
                    <v-col class="text-center">
                      <v-btn color="primary" elevation="0">
                        <v-icon>ri-add-line</v-icon> Program baru
                      </v-btn>
                    </v-col> -->
                  </div>
                </v-col>
              </div>
              <div
                v-else
                v-for="(program, index2) in list"
                :key="'A'+ index2"
                class="col-xl-3 col-md-3 col-sm-6 col-6"
              >
                <v-hover
                  v-slot="{ hover }"
                  close-delay="200"
                >
                  <v-card :elevation="hover ? 16 : 5" :class="{ 'on-hover': hover }" class="mx-auto">              
                    <router-link
                      class=""
                      :to="{ name: 'detail-kelas-user', params: { id: program.id } }"
                      @click.stop
                    >
                      <img
                        :src="program.program_information.cover.url"
                        class="img-studi"
                      />

                      <v-card-subtitle class="pa-3 pb-0" style="min-height: 80px;">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="black--text font-weight-bold text-dots-2 fs-18"
                            >{{program.title}}</span>
                          </template>
                          <span class="text-subtitle-2">{{program.title}}</span>
                        </v-tooltip>
                        <span v-if="program.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{ toCurrency(program.program_information.discount_price)}}</s></span> 
                      </v-card-subtitle>

                      <v-card-text class="pa-3 pt-0">
                        <!-- <p class="color-disable mb-0 fs-14 text-dots-2" v-text="program.program_benefits"></p>                   -->
                        <span v-show="false" class="fs-12 color-strike"><s>Rp 750,000 </s></span>
                        <h3 class="color-state">Rp {{ toCurrency(program.program_information.selling_price)}}</h3>
                      </v-card-text>
                    </router-link>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        rounded
                        class="mx-auto text-center btn-beli"
                        block
                        @click="getDialog(program.id)"
                      >
                        Beli Kelas Online
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </div>
            </div>           
            <div v-if="total_program" class="pagination">
              <!-- <v-pagination
                v-model="page"
                :length="4"
              ></v-pagination> -->
                <v-pagination v-model="page" :length="total_pagination" @input="getListPagination(page)"></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="font-weight-bold">Pilih Platform Pembelian</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="dialog = false"
              class="ml-auto"
              color="primary"
            >
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="pb-0 pt-2">
          <div class="text-center mx-auto py-3">
            <div class="text-center mx-auto py-3">
              <div v-for="(item, index) in link" :key="'A'+ index">
                <div v-if="item.title" class="mb-2 text-wrap pa-2 rounded" style="background-color: #324577;">
                  <a :href="item.url" target="_blank" class="white--text">
                    {{ item.title }}
                  </a>
                </div>
                <!-- <v-btn v-if="item.title"  depressed class="mb-2 white--text" color="primary" :href="item.url" target="_blank" block>{{ item.title }}</v-btn> -->
              </div>
            </div>
          </div>
        </v-card-text>        
      </v-card>
    </v-dialog>
    <v-dialog v-model="filter" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <p class="mb-0">Pencarian Program</p>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="filter = false" color="primary">
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="">
          <v-card class="" elevation="0"> 
              <div class="box-category mb-2">
                <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
                <h3 class="mb-3">Bidang Studi</h3>
               <!--  <v-checkbox
                  v-model="studi_id"
                  label="Semua Program"
                  @click="getList()"
                  value=""
                  hide-details
                  class="mt-1"                  
                  ></v-checkbox> -->
                  <!-- @click="getList()" -->
                <v-checkbox
                  v-for="(item, index) in list_studi" :key="index"
                  v-model="studi_id"
                  :label="item.title"
                  :value="item.id"
                  hide-details
                  class="mt-1"
                  multiple
                  ></v-checkbox>
                  <v-btn v-if="list_studi.length <= 10" color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='-1')" text block>Tampilkan semua <v-icon>ri-arrow-down-s-line</v-icon></v-btn>
                  <v-btn v-else color="primary" class="font-weight-bold mt-2" @click="getStudi(limit='8')" text block>Sembunyikan <v-icon>ri-arrow-up-s-line</v-icon></v-btn>
              </div>
              <div id="title-prog" class="box-category mb-3">
                <h3 class="">Tipe Program</h3>
                  <!-- <v-select
                  :items="list_progs"
                  :item-text="'name'"
                  :item-value="'id'"
                  placeholder="Pilih label program"
                  v-model="label_program"
                  @change="getListType()"
                  dense
                  outlined
                  class="rounded-pill" 
                  hide-details="auto"                 
                ></v-select> -->
                <v-radio-group v-model="label_program" class="mt-2">
                  <v-radio
                    v-for="(item, index) in list_progs" :key="index"
                    :label="item.name"
                    :value="item.id"
                    @click="getListType()"
                    hide-details
                    ></v-radio>
                </v-radio-group>
              </div>
              <div class="box-category mb-5">
                <!-- <p class="fs-18 font-weight-medium mb-1">Bidang Studi</p> -->
                <h3 class="mb-3">Harga</h3>
                <v-text-field
                  
                  v-model="price_start"
                  placeholder="Harga terendah"
                  outlined
                  prefix="Rp"
                  class="rounded-xl mb-3"
                  hide-details="auto"
                ></v-text-field>
                <v-text-field
                  
                  v-model="price_end"
                  placeholder="Harga tertinggi"
                  outlined
                  prefix="Rp"
                  class="rounded-xl"
                  hide-details="auto"
                ></v-text-field>
              </div>
              <div class="box-button mb-2">
                <div class="row">
                  <div class="col-6">
                    <v-btn
                      outlined                      
                      color="primary"
                      class="rounded-xl font-weight-normal"
                      block
                      small
                      @click="getReset"
                    >
                      Reset Filter
                    </v-btn>
                  </div>
                  <div class="col-6">
                    <v-btn
                      depressed                      
                      color="primary"
                      class="rounded-xl font-weight-normal"
                      block
                      small
                      @click="getFilter"
                    >
                      Terapkan
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: "bidang-studi",
  data: () => ({
   list:[],
   ex:[],
   dialog:false,
   filter:false,
   link:[],
   title:'',
   limit:8,
   page:1,
   total_pagination:null,

   list_studi:[],
   studi:[],
   studi_id:[],
   price_start:'',
   price_end:'',
   total_program:'',
   search:'',
   numberValue:null,
   numberValue2:null,
   label_program:null,

   list_progs:
    [
      {id:true,name:"Gratis"},
      {id:false,name:"Berbayar"}
    ],
   // priceRules: [
   //      // (v) => !!v || "Harga terendah Tidak Boleh Kosong",
   //      v => /^[0-9*&./\s/]+$/.test(v) || 'Tidak boleh ada simbol dan huruf',
   //    ],  
   //  price2Rules: [
   //      // (v) => !!v || "Harga tertinggi Tidak Boleh Kosong",
   //      v => /^[0-9*&./\s/]+$/.test(v) || 'Tidak boleh ada simbol dan huruf',
   //    ],  
  }),
  created() {
  },
  mounted() {
    if (this.$route.query.studi) {
      this.studi_id = this.$route.query.studi
    }
    if (this.$route.query.is_free) {
      this.label_program = this.$route.query.is_free
    }
    this.getList();
    this.getStudi(8);
  },
  watch:{
    price_start: function(newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.price_start = result;
      this.numberValue=result;

    },
    numberValue: function(newval){
      const result = newval.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, "");
      this.numberValue = result;
    },
    price_end: function(newValue) {
      const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.price_end = result;
      this.numberValue2=result;

    },
    numberValue2: function(newval){
      const result = newval.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, "");
      this.numberValue2 = result;
    }
  },
  methods: {        
    getStudi(limit){
      // console.log(limit)
      this.limit = limit;
      this.axios
        .get(`study/v1/public/list/${process.env.VUE_APP_ACCESS_KEY}?q=&page=1&limit=${this.limit}&sort=created_at&sort_type=asc`)          
        .then((response) => {
          let res = response.data.data;
          this.list_studi = res.list;
        });
    },
    getList(){
      this.axios
        // .get(`users/v1/public/program/list?status=publish&page=1&limit=10&sort=created_at&dir=desc&study=${this.$route.params.id}`,{
        //     headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        // .get(`users/v1/public/program/list?&page=1&limit=10&sort=_id&dir=desc&study=${this.studi_id}&price_start=${this.price_start}&price_end=${this.price_end}`,{
        //   })
        .get(`users/v1/public/program/list?status=publish&page=${this.page}&limit=10&sort=_id&dir=desc&study=${this.studi_id}&q=${this.search}&is_free=${this.$route.query.is_free}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
          
        .then((response) => {
          let res = response.data.data;
          this.list = res.list;
          this.total_program = res.total;
          this.total_pagination= res.total_page;
            this.filter=false;

          // this.title = res.list[0].learning_targets.studies.title;
        });
    },
    getListPagination(page){
      this.page=page;
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=${this.page}&limit=10&sort=_id&dir=desc&study=${this.studi_id}&q=${this.search}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
          
        .then((response) => {
          let res = response.data.data;
          this.list = res.list;
          this.total_program = res.total;
          this.total_pagination= res.total_page;
            this.filter=false;

          // this.title = res.list[0].learning_targets.studies.title;
        });
    },
    getListType(){
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=${this.page}&limit=10&sort=_id&dir=desc&study=${this.studi_id}&q=${this.search}&is_free=${this.label_program}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
          
        .then((response) => {
          let res = response.data.data;
          this.list = res.list;
          this.total_program = res.total;
          this.total_pagination= res.total_page;
            this.filter=false;

          // this.title = res.list[0].learning_targets.studies.title;
        });
    },
    getReset(){
      this.price_end='';
      this.price_start='';
      this.numberValue=null;
      this.numberValue2=null;
      this.studi_id=[];
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=1&limit=10&sort=_id&dir=desc&study=${this.studi_id}&q=${this.search}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
          
        .then((response) => {
          let res = response.data.data;
          this.list = res.list;
          this.total_program = res.total;
          this.total_pagination= res.total_page;
            this.filter=false;

          // this.title = res.list[0].learning_targets.studies.title;
        });
    },
    getFilter(){
      if (this.price_start === '' || this.price_end === '' ) {
        this.getList()
      }else{        
        this.axios
          .get(`users/v1/public/program/list?status=publish&page=${this.page}&limit=10&sort=_id&dir=desc&study=${this.studi_id}&price_start=${this.numberValue}&price_end=${this.numberValue2}&q=${this.search}`,{
              headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
            })
            
          .then((response) => {
            let res = response.data.data;
            this.list = res.list;
            this.total_program = res.total;
            this.total_pagination= res.total_page;
            this.filter=false;
            // this.title = res.list[0].learning_targets.studies.title;
          });
      }
    },
    getDialog(id){
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          // let res = response.data.data;
          this.link= response.data.data.program_information.selling_link;
          this.dialog=true;
        });
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
          return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'IDR',
          // minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
};
</script>

<style>
#section-trans{
  background: linear-gradient(#f05326,#FF9A3F);
  padding: 80px 0;
  /*min-height: 100vh;*/
}
#section-trans-2{
  padding: 100px 0;
  /*background: #fff;*/
}
.after-line::after{
  content: '';
  margin: 10px auto;
  border-bottom: 4px solid #3E7FB5;
  width: 15%;
  display: block;
}
.filter-search {
    right: 40%;
    left: 35%;
    bottom: 2%;
    position: fixed;
    line-height: inherit;
    z-index: 99;
  }
.box-search{
  max-width: 400px;
  margin-left: auto!important;
}
.img-studi {
  width: 100%;
  height: 150px;
  object-fit: fill;
}
/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .box-search{max-width: 100%;}
}
@media (max-width: 375px) {
  
}
</style>